import React from "react"

const ErrorMessage = ({ message }) => {
  return (
    <div>
      <span className="text-red-900 text-sm"> {message || ""}</span>
    </div>
  )
}

export default ErrorMessage
